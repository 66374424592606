/* global window */

import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
// import { useViewportScroll, useTransform, useSpring, motion, useMotionValue } from 'framer-motion';

import { childrenPropType } from '../../utils/propTypes';

function calculateMinHeight(height, range) {
  return height + height * range;
}

function randBetween(min = 0, max = 100) {
  return Math.floor(Math.random() * (+max - +min)) + +min;
}

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const BASE_OFFSET = 600;
const MIN_RANGE = 0.2;

const ParallaxItem = ({ className, children, range }) => {
  // const { scrollY } = useViewportScroll();
  const ref = useRef();
  // const offsetTop = useMotionValue(0);
  const [minHeight, setMinHeight] = useState('auto');

  // const springConfig = {
  //   damping: 100,
  //   stiffness: 100,
  //   mass: randBetween(1, 3)
  // };

  // const relativeScrollY = useTransform(
  //   [offsetTop, scrollY],
  //   ([latestOffsetTop, latestScrollY]) => latestScrollY - (latestOffsetTop || 0)
  // );

  // const y = useSpring(
  //   useTransform(
  //     relativeScrollY,
  //     [-BASE_OFFSET, BASE_OFFSET * (range <= MIN_RANGE ? 1 + range : range)],
  //     [`0%`, `${range * 100}%`]
  //   ),
  //   springConfig
  // );

  useIsomorphicLayoutEffect(() => {
    if (!ref.current) return null;
    const onResize = () => {
      // if (ref.current.offsetParent) offsetTop.set(ref.current.offsetParent.offsetTop);
      setMinHeight(calculateMinHeight(ref.current.offsetHeight, range));
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [ref]);

  return (
    <div style={{ minHeight }} className={className}>
      <div ref={ref}>{children}</div>
    </div>
  );
};

ParallaxItem.propTypes = {
  range: PropTypes.number,
  className: PropTypes.string,
  children: childrenPropType
};

ParallaxItem.defaultProps = {
  className: '',
  children: null,
  range: MIN_RANGE
};

export default ParallaxItem;
