import PropTypes from 'prop-types';

export const childrenPropType = PropTypes.oneOfType([
  //
  PropTypes.node,
  PropTypes.func,
  PropTypes.string,
  PropTypes.number
]);

export default { childrenPropType };
