import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useIntersection from './useIntersection';
import { childrenPropType } from '../../utils/propTypes';

export const IntersectionContext = React.createContext({ inView: true });

export const IntersectionObserver = ({ children, threshold, reset }) => {
  const [inView, setInView] = useState(false);
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    threshold
  });

  useEffect(() => {
    const inViewNow = intersection && intersection.intersectionRatio > threshold;
    if (inViewNow) {
      return setInView(inViewNow);
    }
    if (reset) {
      return setInView(false);
    }

    return undefined;
  }, [intersection, reset]);

  return (
    <IntersectionContext.Provider value={{ inView }}>
      {children({ ref: intersectionRef })}
      {/* <div ref={intersectionRef}>{children}</div> */}
    </IntersectionContext.Provider>
  );
};

IntersectionObserver.propTypes = {
  children: childrenPropType,
  threshold: PropTypes.number,
  reset: PropTypes.bool
};

IntersectionObserver.defaultProps = {
  children: null,
  threshold: 0,
  reset: false // if value set to true - observed element will reappear every time it shows up on the screen
};
