import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Head from 'next/head';

import useTranslation from 'next-translate/useTranslation';
import sidekickInit from '../../utils/sidekick/init';
import { CtaHeroPropTypes } from '../CtaHero';
import { StandardHeroPropTypes } from '../StandardHero';
import { ModuleTextPropTypes } from '../ModuleText';
import { ModuleHorizontalPropTypes } from '../ModuleHorizontal';
import { CardListPropTypes } from '../CardList';
import { BannerFullSizePropTypes } from '../BannerFullSize';
import { ModuleTextWithSubheadersPropTypes } from '../ModuleTextWithSubheaders';
import { SectionPropTypes } from '../Section';

// import '../../styles/base/html.scss';
import styles from './PageGeneral.module.scss';
import darkStyles from './DarkStyles.module.scss';
import denseStyles from './DenseStyles.module.scss';
import pdpStyles from './PdpStyles.module.scss';
import { IntersectionContext, IntersectionObserver } from '../../generics/IntersectionObserver';

import useGradientConfig from '../../utils/hooks/useGradientConfig';
// import AnimatedBackground from '../../generics/AnimatedBackground';
import dynamic from 'next/dynamic';
import ContentModule from '../ContentModule';
import ModuleFormPropTypes from '../ModuleForm/ModuleFormPropTypes';
const ConfirmedAccountToast = dynamic(() =>
  import(
    '../../components-v2/AuthenticatePage/components/ConfirmedAccountToast/ConfirmedAccountToast'
  )
);
const Subnav = dynamic(() => import('../Subnav'));
const ErrorBoundary = dynamic(() => import('../ErrorBoundary'));

const THEME_STYLES = {
  dark: darkStyles,
  dense: denseStyles,
  pdp: pdpStyles,
  404: pdpStyles
};

export const PageGeneralPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  animation: PropTypes.shape({
    enabled: PropTypes.bool
  }),
  themeJson: PropTypes.shape({
    style: PropTypes.string,
    basePadding: PropTypes.string
  }),
  internalTitle: PropTypes.string.isRequired,
  hero: PropTypes.oneOfType([
    PropTypes.shape(CtaHeroPropTypes),
    PropTypes.shape(StandardHeroPropTypes)
  ]),
  contents: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(CtaHeroPropTypes),
      PropTypes.shape(StandardHeroPropTypes),
      PropTypes.shape(ModuleTextPropTypes),
      PropTypes.shape(ModuleHorizontalPropTypes),
      PropTypes.shape(CardListPropTypes),
      PropTypes.shape(BannerFullSizePropTypes),
      PropTypes.shape(ModuleTextWithSubheadersPropTypes),
      PropTypes.shape(ModuleFormPropTypes),
      PropTypes.shape(SectionPropTypes)
    ])
  )
};

const variants = {
  show: {
    transition: { staggerChildren: 0.3, delay: 0.5 }
  },
  hidden: {
    transition: { staggerChildren: 0.2, staggerDirection: -1 }
  }
};

const lastModulePadding = (content) => {
  const last = content?.length ? content[content.length - 1] : {};
  return !(
    last._contentTypeId === 'bannerFullSize' ||
    last._contentTypeId === 'moduleHorizontal' ||
    (last._contentTypeId === 'moduleForm' && last.formStyle === 'Footer')
  );
};

const displayHero = (hero, hasSubnav) =>
  hero &&
  (() => {
    const { _id: id, _contentTypeId: cId } = hero;
    const fields = hasSubnav ? { ...hero, hasSubnav, scrollText: null } : hero;
    return <ContentModule id={id} contentTypeId={cId} fields={fields} />;
  })();

const animatedSection = ({ contentModule, calculatorRef, animation, theme }) => {
  return (
    <IntersectionObserver key={contentModule._id} threshold={contentModule.threshold ?? 0.1}>
      {({ ref }) => (
        <Section
          contentModule={{
            ...contentModule,
            animation: contentModule.animation ?? animation,
            theme: contentModule.theme ?? theme
          }}
          ref={(_ref) => {
            // eslint-disable-next-line no-param-reassign
            ref.current = _ref;
            if (contentModule._contentTypeId === 'moduleImpactCalculator') {
              // eslint-disable-next-line no-param-reassign
              calculatorRef.current = _ref;
            }
          }}
        />
      )}
    </IntersectionObserver>
  );
};

const staticSection = ({ contentModule, calculatorRef, animation, theme }) => {
  return (
    <Section
      contentModule={{
        ...contentModule,
        animation: contentModule.animation ?? animation,
        theme: contentModule.theme ?? theme
      }}
      ref={(_ref) => {
        if (contentModule._contentTypeId === 'moduleImpactCalculator') {
          // eslint-disable-next-line no-param-reassign
          calculatorRef.current = _ref;
        }
      }}
    />
  );
};

function PageGeneral({ _id, _contentTypeId, internalTitle, hero, contents, animation, themeJson }) {
  const content = contents;
  const { gradientConfig, calculatorRef } = useGradientConfig();
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });

  const subNavList = content ? content.filter((contentItem) => contentItem.style === 'subnav') : [];
  const padLastModule = useMemo(
    () => lastModulePadding(content) && themeJson?.style !== '404',
    [content, themeJson?.style]
  );
  const shouldHideScrollText = subNavList && subNavList.length > 0;
  const { lang } = useTranslation();

  return (
    <ErrorBoundary>
      <Head>
        <meta name="content_type" content="pageGeneral" />
      </Head>
      {/* Needed to add extra padding to bottom of /ca/sell-impossible SUPPORT-2683 */}
      <div
        {...sidekicker('Page General')}
        className={cx(
          THEME_STYLES[themeJson?.style]?.page,
          themeJson?.style,
          lang === 'ca' && _id === '2akq9xSqSFprZHtD5j9k34' ? styles.extraPadding : ''
        )}>
        {displayHero(hero, shouldHideScrollText)}
        {subNavList.length > 0 && (
          <Subnav
            _id={_id}
            _contentTypeId={_contentTypeId}
            internalTitle={internalTitle}
            link={subNavList}
            hero={hero}
          />
        )}
        {/* Theme Style dark is ONLY used in ImpactReport2020 which is where the AnimatedBackground is needed */}
        {/* {themeJson?.style === 'dark' ? (
          <AnimatedBackground key={gradientConfig.impactTop} {...gradientConfig} />
        ) : null} */}

        {content && !!content.length && (
          <div
            data-testid="PageGeneral-modules"
            className={cx(
              styles.modules,
              padLastModule ? styles.basePadding : undefined,
              'modules',
              _id === '3TRldpnFJ9vSGj0BUTOdlK' ? styles.locationsPage : '',
              _id === '5pXLoqIHdqHAeSZY6TLo7b' ? styles.noSectionPadding : ''
            )}
            style={{
              ...(themeJson?.basePadding && {
                'padding-bottom': themeJson?.basePadding
              })
            }}
            {...sidekicker('Content')}>
            {content.map(
              (
                contentModule // TODO: Improve conditional wrapping sections
              ) => {
                if (contentModule.style === 'subnav') {
                  if (contentModule.href?.includes('#')) {
                    return (
                      <div
                        className={styles.sectionSubnav}
                        id={contentModule.href.replace('#', '')}
                      />
                    );
                  }
                  return null;
                }
                return animation?.enabled
                  ? animatedSection({
                      contentModule,
                      calculatorRef,
                      animation,
                      theme: themeJson
                    })
                  : staticSection({
                      contentModule,
                      calculatorRef,
                      animation,
                      theme: themeJson
                    });
              }
            )}
          </div>
        )}
        <ConfirmedAccountToast />
      </div>
    </ErrorBoundary>
  );
}

const Section = React.forwardRef(({ contentModule }, ref) => {
  const { inView } = React.useContext(IntersectionContext);
  const {
    _id: id,
    _contentTypeId,
    contentTypeId,
    animation,
    variant,
    themeJson = {}
  } = contentModule;
  const themeSectionStyle = THEME_STYLES[themeJson?.style]
    ? THEME_STYLES[themeJson?.style][`section_${id}`]
    : null;
  const type = _contentTypeId || contentTypeId;

  const contentType = variant ? `${type}:${variant}` : type;

  return (
    <section
      ref={ref}
      initial={false}
      animate={!animation?.enabled || inView ? 'show' : 'hidden'}
      variants={variants}
      key={id}
      id={id}
      data-contentVariant={contentType}
      className={cx([`Section_${_contentTypeId}`, styles[`section_${id}`], themeSectionStyle])}>
      <ContentModule id={id} contentTypeId={_contentTypeId} fields={contentModule} />
    </section>
  );
});

Section.propTypes = {
  contentModule: PropTypes.oneOfType([
    PropTypes.shape(CtaHeroPropTypes),
    PropTypes.shape(StandardHeroPropTypes),
    PropTypes.shape(ModuleTextPropTypes),
    PropTypes.shape(ModuleHorizontalPropTypes),
    PropTypes.shape(CardListPropTypes),
    PropTypes.shape(BannerFullSizePropTypes),
    PropTypes.shape(ModuleTextWithSubheadersPropTypes)
  ]).isRequired
};
PageGeneral.propTypes = PageGeneralPropTypes;

PageGeneral.defaultProps = {
  contents: [],
  hero: null,
  animation: {},
  themeJson: {}
};

export default PageGeneral;
