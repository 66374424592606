import { useEffect, useState } from 'react';

const useIntersection = function (ref, options) {
  const _a = useState(null);
  const intersectionObserverEntry = _a[0];
  const setIntersectionObserverEntry = _a[1];
  useEffect(() => {
    if (ref.current && typeof IntersectionObserver === 'function') {
      const handler = (entries) => {
        setIntersectionObserverEntry(entries[0]);
      };
      const observer = new IntersectionObserver(handler, options);
      observer.observe(ref.current);
      return () => {
        setIntersectionObserverEntry(null);
        observer.disconnect();
      };
    }
  }, [ref.current, options.threshold, options.root, options.rootMargin]);
  return intersectionObserverEntry;
};

export default useIntersection;
