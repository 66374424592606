/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { ModuleTextPropTypes } from '../ModuleText';
import { CardPropTypes } from '../Card';
import { ElementLinkPropTypes } from '../ElementLink';

import ContentModule from '../ContentModule';

export const CardListPropTypes = {
  _id: PropTypes.string.isRequired,
  titleModule: PropTypes.shape(ModuleTextPropTypes),
  cards: PropTypes.arrayOf(PropTypes.shape(CardPropTypes)).isRequired,
  cardStyle: PropTypes.oneOf(['Promo', 'Save / Share', 'Info', 'Profile']).isRequired,
  listStyle: PropTypes.oneOf(['3 per row', '2-1 unit', '3-1-3 rows', 'Carousel', 'Timeline'])
    .isRequired,
  link: PropTypes.shape(ElementLinkPropTypes)
};

function CardList(fields) {
  const { _contentTypeId, titleModule, listStyle } = fields;
  const variant = listStyle;

  return (
    <>
      {titleModule && (
        <ContentModule contentTypeId={titleModule.contentTypeId} fields={titleModule} />
      )}
      <ContentModule contentTypeId={_contentTypeId} fields={fields} variant={variant} />
    </>
  );
}

CardList.propTypes = CardListPropTypes;

CardList.defaultProps = {
  titleModule: null,
  link: null
};

export default CardList;
