import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';

import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core';
import sidekickInit from '../../utils/sidekick/init';
import Image, { ImagePropTypes } from '../Image';
import ModuleText, { ModuleTextPropTypes } from '../ModuleText';
import RichTextParser from '../RichTextParser';
import styles from './ModuleHorizontal.module.scss';
import ErrorBoundary from '../ErrorBoundary';
import ParallaxItem from '../../generics/ParallaxIItem';
import useLockedOffsetTop from './useLockedOffsetTop';

import ContentModule from '../ContentModule';

export const ModuleHorizontalPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  image: PropTypes.shape(ImagePropTypes).isRequired,
  moduleText: PropTypes.shape(ModuleTextPropTypes),
  disclaimerText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  animation: PropTypes.shape(),
  contentCard: PropTypes.shape({ _contentTypeId: PropTypes.string.isRequired }),
  anchor: PropTypes.string,
  proportionLeft: PropTypes.number,
  proportionRight: PropTypes.number
};

const variants = {
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3
    }
  },
  hidden: {
    opacity: 0
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.overrides?.ModuleHorizontal?.root
  }
}));

const displayDisclaimer = (disclaimer) =>
  disclaimer ? (
    <div className={cx(styles.disclaimer)}>
      <RichTextParser document={disclaimer} />
    </div>
  ) : null;

function ModuleHorizontal({
  _id,
  _contentTypeId,
  internalTitle,
  layout,
  image,
  contentCard,
  moduleText,
  disclaimerText,
  anchor,
  animation,
  proportionLeft,
  proportionRight
}) {
  const { leftRef, rightRef } = useLockedOffsetTop();
  const theme = useTheme();
  const classes = useStyles();

  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });
  return (
    <ErrorBoundary>
      <div
        data-testid="ModuleHorizontal"
        id={anchor?.toLowerCase()}
        className={cx('ModuleHorizontal', classes.root)}
        {...theme.styleOverrides?.ModuleHorizontal?.root}
        {...sidekicker('Module Horizontal')}>
        <div
          className={cx(
            'ModuleHorizontal-wrap',
            styles.wrap,
            styles[`wrap-${kebabCase(layout)}`],
            'container mt-0 mt-md-0'
          )}>
          <div
            data-testid="ModuleHorizontal-layout"
            className={cx('ModuleHorizontal-layout', styles[kebabCase(layout)], styles.module)}>
            {moduleText && (
              <div
                variants={variants}
                className={cx(
                  'ModuleHorizontal-moduleText',
                  `col-12 col-md-${proportionLeft}`,
                  styles.text,
                  'content-text'
                )}>
                <ModuleText
                  ref={leftRef}
                  {...moduleText}
                  variant="module-horizontal"
                  {...(image && isEmpty(contentCard) && { mobileImage: image })}
                  {...(image &&
                    disclaimerText &&
                    isEmpty(contentCard) && {
                      mobileDisclaimer: disclaimerText
                    })}
                />
              </div>
            )}
            {image && isEmpty(contentCard) && (
              <div
                className={cx(
                  'ModuleHorizontal-image',
                  `col-12 col-md-${proportionRight}`,
                  styles.image,
                  animation?.enabled && 'animated',
                  'content-image'
                )}>
                <ParallaxItem range={animation?.enabled ? 0.2 : 0}>
                  <div
                    className="position-relative"
                    transition={{
                      type: 'spring',
                      damping: 100,
                      stiffness: 100,
                      mass: 2
                    }}
                    ref={rightRef}
                    // animate={{ top }}
                  >
                    <div className={styles.shim} />
                    {image && image.url?.indexOf('.mp4') > -1 ? (
                      <BgVideo muted loop autoPlay preload="auto" playsInline src={image.url} />
                    ) : (
                      <Image
                        testId="ModuleHorizontal-image"
                        image={image}
                        columns={6}
                        {...sidekicker('Image')}
                      />
                    )}
                  </div>
                </ParallaxItem>
                {displayDisclaimer(disclaimerText)}
              </div>
            )}
            {!isEmpty(contentCard) ? (
              <div className={cx(`col-12 col-md-${proportionRight}`)}>
                <ContentModule contentTypeId={contentCard._contentTypeId} fields={contentCard} />
                {displayDisclaimer(disclaimerText)}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

const BgVideo = styled.video`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

ModuleHorizontal.propTypes = ModuleHorizontalPropTypes;
ModuleHorizontal.defaultProps = {
  moduleText: null,
  anchor: '',
  animation: {},
  contentCard: null,
  proportionLeft: 6,
  proportionRight: 6,
  disclaimerText: null
};

export default ModuleHorizontal;
