import * as React from 'react';

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

const IMPACT_TOP_OFFSET = 900;
const IMPACT_START_OFFSET = 900;
const useGradientConfig = () => {
  const calculatorRef = React.useRef(null);
  const [gradientConfig, setGradientConfig] = React.useState({
    steps: []
  });
  useIsomorphicLayoutEffect(() => {
    const onResize = () => {
      if (calculatorRef.current) {
        const { offsetTop, scrollHeight } = calculatorRef.current;
        const impactTop = offsetTop - IMPACT_TOP_OFFSET;
        const impactStart = impactTop - IMPACT_START_OFFSET;

        const impactBottom = offsetTop + scrollHeight - 700;
        setGradientConfig({
          steps: [
            {
              color: '#52bbea',
              inputRange: [0, impactStart, impactTop - 10, impactTop],
              outputRange: [0, 0, 0.8, 1]
            },
            {
              color: '#00dda5',
              inputRange: [0, impactBottom - 2, impactBottom - 1, impactBottom],
              outputRange: [0, 0, 1, 1]
            }
          ],
          impactTop,
          impactStart,
          impactBottom
        });
      }
    };
    // TODO: find a cleaner way to wait a bit for the paint to happen
    setTimeout(() => onResize(), 500);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [calculatorRef]);
  return { gradientConfig, calculatorRef };
};

export default useGradientConfig;
