/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import sidekickInit from '../../utils/sidekick/init';
import styles from './ModuleTextWithSubheaders.module.scss';
import ErrorBoundary from '../ErrorBoundary';
import TradeMark from '../../generics/TradeMark';

export const ModuleTextWithSubheadersPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  anchor: PropTypes.string
};

function ModuleTextWithSubheaders({ _id, _contentTypeId, internalTitle, title, body, anchor }) {
  return (
    <ErrorBoundary>
      <div
        data-testid="ModuleTextWithSubheaders"
        id={anchor.toLowerCase()}
        {...sidekickInit({ _id, _contentTypeId, internalTitle }).sidekicker(
          'Module Text With Subheaders'
        )}
      >
        <div className="container">
          <div className="row">
            <div className={styles.module}>
              {title && (
                <h2 className="h2" data-testid="ModuleTextWithSubheaders-title">
                  <TradeMark>{title}</TradeMark>
                </h2>
              )}
              {body && (
                <div data-testid="ModuleTextWithSubheaders-body">
                  <TradeMark>{body}</TradeMark>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

ModuleTextWithSubheaders.propTypes = ModuleTextWithSubheadersPropTypes;

ModuleTextWithSubheaders.defaultProps = {
  anchor: ''
};

export default ModuleTextWithSubheaders;
