import React from 'react';
import Box from '@material-ui/core/Box';
import cx from 'classnames';

import PropTypes from 'prop-types';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import get from 'lodash/get';
import BackgroundImage from '../../generics/BackgroundImage';
import { ImagePropTypes } from '../Image';
import sidekickInit from '../../utils/sidekick/init';

import ContentModule from '../ContentModule';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    ...theme.overrides?.Section?.root,
    marginBottom: 0
  },
  gridItem: {
    ...theme.overrides?.Section?.gridItem
  },
  gridContainer: {
    ...theme.overrides?.Section?.gridContainer
  },
  backgroundImage: {
    ...theme.overrides?.Section?.backgroundImage
  }
}));

const Section = (props) => {
  const {
    _id,
    _contentTypeId,
    internalTitle,
    contents,
    backgroundDesktop,
    backgroundTablet,
    backgroundMobile
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      {...sidekickInit({ _id, _contentTypeId, internalTitle }).sidekicker('Section')}
      className={classes.root}
      {...theme.styleOverrides?.Section?.root}
    >
      <BackgroundImage
        className={cx('Section-background', classes.backgroundImage)}
        image={backgroundDesktop}
        imageTablet={backgroundTablet}
        imageMobile={backgroundMobile}
      />
      <Grid
        container
        className={classes.gridContainer}
        {...theme.styleOverrides?.Section?.gridContainer}
      >
        {contents?.map((content, idx) => (
          <Grid
            key={content._id}
            item
            xs={12}
            className={classes.gridItem}
            {...get(theme.styleOverrides?.Section?.gridItem || [], idx)}
          >
            <ContentModule contentTypeId={content?._contentTypeId} fields={content} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export const SectionPropTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape({})),
  backgroundDesktop: ImagePropTypes,
  backgroundTablet: ImagePropTypes,
  backgroundMobile: ImagePropTypes,
  _id: PropTypes.string,
  _contentTypeId: PropTypes.string,
  internalTitle: PropTypes.string
};
Section.propTypes = SectionPropTypes;

Section.defaultProps = {
  contents: null,
  backgroundDesktop: null,
  backgroundTablet: null,
  backgroundMobile: null,
  _id: null,
  _contentTypeId: null,
  internalTitle: null
};

export default Section;
